var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "listEmptyMessage" },
    [
      _vm.iconName
        ? _c("icon", {
            staticClass: "listEmptyMessage__icon",
            attrs: { name: _vm.iconName },
          })
        : _vm._e(),
      _vm.title
        ? _c("h3", { staticClass: "listEmptyMessage__title" }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
      _vm.subtitle
        ? _c("p", { staticClass: "listEmptyMessage__subtitle" }, [
            _vm._v(_vm._s(_vm.subtitle)),
          ])
        : _vm._e(),
      _vm.linkTo
        ? _c(
            "router-link",
            {
              staticClass: "btn btn--lg btn--primary",
              attrs: { to: _vm.button.url },
            },
            [_vm._v(_vm._s(_vm.button.text))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }