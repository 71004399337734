var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.parentState !== _vm.ParentStateType.Purchases
        ? [
            !_vm.beatPlaylist || _vm.beatPlaylist.length <= 0
              ? _c("list-empty-message", {
                  attrs: {
                    "icon-name": "license",
                    title: "Look like there's nothing here yet.",
                    subtitle:
                      "Go back to our browse page to start a new search",
                    "link-to": "Browse",
                  },
                })
              : [
                  _c("table", { staticClass: "beat-table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { attrs: { width: "38" } }),
                        _vm.parentState === _vm.ParentStateType.Trending
                          ? _c(
                              "th",
                              {
                                staticClass: "table-cell__trending",
                                attrs: { width: "38" },
                              },
                              [_vm._v("#")]
                            )
                          : _vm._e(),
                        _c("th", { attrs: { width: "38" } }),
                        _c("th", { staticClass: "table-cell--padding-left" }, [
                          _vm._v("BEAT"),
                        ]),
                        _vm.parentState !== _vm.ParentStateType.Producer
                          ? _c("th", [_vm._v("PRODUCER")])
                          : _vm._e(),
                        _vm.parentState !== _vm.ParentStateType.ArtistType
                          ? _c("th", [_vm._v("ARTIST TYPE")])
                          : _vm._e(),
                        _vm.parentState === _vm.ParentStateType.ArtistType ||
                        _vm.parentState === _vm.ParentStateType.Producer
                          ? _c("th", [_vm._v("GENRE")])
                          : _vm._e(),
                        _c("th", { staticClass: "table-cell__beat-info" }, [
                          _vm._v("BPM"),
                        ]),
                        _c(
                          "th",
                          { staticClass: "table-cell__beat-info" },
                          [
                            _c("icon", {
                              staticClass: "icon-timer",
                              attrs: { name: "timer" },
                            }),
                          ],
                          1
                        ),
                        _c("th", { staticClass: "table-cell--center" }, [
                          _vm._v("DEMO DL"),
                        ]),
                        _c(
                          "th",
                          {
                            staticClass: "table-cell--center",
                            attrs: { width: "100" },
                          },
                          [_vm._v("ADD TO CART")]
                        ),
                        _c("th"),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.beatPlaylist, function (beat, index) {
                        return _c("BeatTableItem", {
                          key: "" + beat.id + (beat.isPromoted ? "ad" : ""),
                          attrs: {
                            index: index,
                            fakeIndexToShow:
                              index - _vm.amountOfPromotedBeats + 1,
                            beatPlaylist: _vm.beatPlaylist,
                            parentState: _vm.parentState,
                          },
                        })
                      }),
                      1
                    ),
                  ]),
                  _vm.parentState !== _vm.ParentStateType.Trending &&
                  _vm.parentState !== _vm.ParentStateType.Favourites &&
                  _vm.parentState !== _vm.ParentStateType.RelatedBeats
                    ? _c(
                        "infinite-loading",
                        {
                          attrs: { spinner: "spiral" },
                          on: { infinite: _vm.infiniteHandler },
                        },
                        [
                          _c("span", {
                            attrs: { slot: "no-more" },
                            slot: "no-more",
                          }),
                          _c("span", {
                            attrs: { slot: "no-results" },
                            slot: "no-results",
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
          ]
        : [
            !_vm.beatPlaylist || _vm.beatPlaylist.length === 0
              ? _c("list-empty-message", {
                  attrs: {
                    "icon-name": "license",
                    title: "Look like you haven't licensed any beats yet.",
                    subtitle:
                      "Browse our catalog first, and find the perfect beat for you.",
                    "link-to": "Browse",
                  },
                })
              : _c(
                  "table",
                  { staticClass: "beat-table beat-table--licenses" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { attrs: { width: "38" } }),
                        _c("th", { attrs: { width: "38" } }),
                        _c("th", { staticClass: "table-cell--padding-left" }, [
                          _vm._v("BEAT"),
                        ]),
                        _c("th", [_vm._v("PRODUCER")]),
                        _c("th", { staticClass: "table-cell__beat-info" }, [
                          _vm._v("BPM"),
                        ]),
                        _c(
                          "th",
                          { staticClass: "table-cell__beat-info" },
                          [
                            _c("icon", {
                              staticClass: "icon-timer",
                              attrs: { name: "timer" },
                            }),
                          ],
                          1
                        ),
                        _c("th", { staticClass: "table-cell--center" }, [
                          _vm._v("DOWNLOAD BEAT FILES"),
                        ]),
                        _c("th", { staticClass: "table-cell--center" }, [
                          _vm._v("CONTRACT"),
                        ]),
                        _c("th", { staticClass: "table-cell--center" }, [
                          _vm._v("UPGRADE"),
                        ]),
                        _c("th"),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.beatPlaylist, function (beat, index) {
                        return _c("BeatTableItem", {
                          key: beat.id,
                          attrs: {
                            index: index,
                            beatPlaylist: _vm.beatPlaylist,
                            parentState: _vm.parentState,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }