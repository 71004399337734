var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { class: _vm.audioClasses }, [
    _c("td", { staticClass: "table-cell table-cell__play-controls" }, [
      _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: _vm.beatImageOrProducerLogo(_vm.beat),
            expression: "beatImageOrProducerLogo(beat)",
          },
        ],
        staticClass: "table-cell__image",
        on: { click: _vm.togglePlayStatus },
      }),
      _c(
        "a",
        { on: { click: _vm.togglePlayStatus } },
        [
          _c("icon", { staticClass: "icon-play", attrs: { name: "play" } }),
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isAudioPaused && _vm.beat === _vm.beatPlaying,
                expression: "!isAudioPaused && beat === beatPlaying",
              },
            ],
            staticClass: "icon-pause",
            attrs: { name: "pause" },
          }),
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isAudioPaused && _vm.beat === _vm.beatPlaying,
                expression: "!isAudioPaused && beat === beatPlaying",
              },
            ],
            staticClass: "icon-speaker",
            attrs: { name: "speaker" },
          }),
        ],
        1
      ),
    ]),
    _vm.parentState === _vm.ParentStateType.Trending
      ? _c(
          "td",
          { staticClass: "table-cell table-cell__trending" },
          [
            _vm.beat.isPromoted
              ? _c("p", { staticClass: "table-cell__ad" }, [_vm._v("Ad")])
              : _c("p", { staticClass: "table-cell__chart-position" }, [
                  _vm._v(_vm._s(_vm.fakeIndexToShow)),
                ]),
            _c("icon", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.beat.trending_state === "New",
                  expression: "beat.trending_state === 'New'",
                },
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "New",
                  expression: "'New'",
                },
              ],
              staticClass: "table-cell__trendingIcon",
              attrs: { name: "chart-new", color: "#1FA4EE" },
            }),
            _c("icon", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.beat.trending_state === "Up",
                  expression: "beat.trending_state === 'Up'",
                },
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Up",
                  expression: "'Up'",
                },
              ],
              staticClass: "table-cell__trendingIcon",
              attrs: { name: "chart-up", color: "#0DEF33" },
            }),
            _c("icon", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.beat.trending_state === "Same",
                  expression: "beat.trending_state === 'Same'",
                },
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Same",
                  expression: "'Same'",
                },
              ],
              staticClass: "table-cell__trendingIcon",
              attrs: { name: "chart-same", color: "#909090" },
            }),
            _c("icon", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.beat.trending_state === "Down",
                  expression: "beat.trending_state === 'Down'",
                },
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Down",
                  expression: "'Down'",
                },
              ],
              staticClass: "table-cell__trendingIcon",
              attrs: { name: "chart-down", color: "#DB1414" },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "td",
      {
        staticClass: "table-cell table-cell--center table-cell--hide-on-mobile",
      },
      [
        _c(
          "a",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Like Beat",
                expression: "'Like Beat'",
              },
            ],
            on: {
              click: function ($event) {
                return _vm.toggleLike(_vm.beat)
              },
            },
          },
          [
            _c("icon", {
              staticClass: "icon-like",
              attrs: { name: _vm.beatIsLiked ? "heart" : "heart-o" },
            }),
          ],
          1
        ),
      ]
    ),
    _c("td", { staticClass: "table-cell table-cell__beat-name" }, [
      _c(
        "a",
        { on: { click: _vm.togglePlayStatus } },
        [
          _vm._v(_vm._s(_vm.beat.name)),
          _vm.beat.sampled
            ? _c("icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Contains Samples",
                    expression: "'Contains Samples'",
                  },
                ],
                staticClass: "icon-copyright",
                attrs: { name: "copyright" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "table-cell--mobile-only table-cell--small-grey" },
            [
              _vm._v(
                _vm._s(
                  _vm.parentState === _vm.ParentStateType.Producer
                    ? _vm.beat.artist_type.name
                    : _vm.beat.producer.display_name
                )
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm.parentState !== _vm.ParentStateType.Producer
      ? _c(
          "td",
          {
            staticClass:
              "table-cell table-cell__router-link table-cell--hide-on-mobile",
          },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Producer",
                    params: { slug: _vm.beat.producer.slug },
                  },
                  title: _vm.beat.producer.display_name,
                },
              },
              [_vm._v(_vm._s(_vm.beat.producer.display_name))]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.parentState !== _vm.ParentStateType.ArtistType &&
    _vm.parentState !== _vm.ParentStateType.Purchases
      ? _c(
          "td",
          {
            staticClass:
              "table-cell table-cell__router-link table-cell--hide-on-mobile",
          },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "ArtistType",
                    params: { slug: _vm.beat.artist_type.slug },
                  },
                  title: _vm.beat.artist_type.name,
                },
              },
              [_vm._v(_vm._s(_vm.beat.artist_type.name))]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.parentState === _vm.ParentStateType.ArtistType ||
    _vm.parentState === _vm.ParentStateType.Producer
      ? _c(
          "td",
          {
            staticClass:
              "table-cell table-cell__router-link table-cell__router-link--small table-cell--hide-on-mobile",
          },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: { name: "Genre", params: { slug: _vm.beat.genre.slug } },
                  title: _vm.beat.genre.name,
                },
              },
              [_vm._v(_vm._s(_vm.beat.genre.name))]
            ),
          ],
          1
        )
      : _vm._e(),
    _c("td", { staticClass: "table-cell table-cell__beat-info" }, [
      _vm._v(_vm._s(_vm.beat.bpm)),
    ]),
    _c("td", { staticClass: "table-cell table-cell__beat-info" }, [
      _vm._v(_vm._s(_vm._f("time")(_vm.beat.length))),
    ]),
    _vm.parentState === _vm.ParentStateType.Purchases
      ? _c(
          "td",
          {
            staticClass:
              "table-cell table-cell--hide-on-mobile table-cell__license-group",
          },
          [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Download MP3",
                    expression: "'Download MP3'",
                  },
                ],
                staticClass: "btn btn--border btn--white",
                on: {
                  click: function ($event) {
                    return _vm.downloadFileType({
                      beatId: _vm.beat.id,
                      fileType: _vm.AudioFileType.Mp3,
                    })
                  },
                },
              },
              [_c("icon", { attrs: { name: "download" } }), _vm._v("MP3")],
              1
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Download WAV",
                    expression: "'Download WAV'",
                  },
                ],
                staticClass: "btn btn--border btn--white",
                class: { isDisabled: !_vm.license.wav },
                on: {
                  click: function ($event) {
                    return _vm.downloadFileType({
                      beatId: _vm.beat.id,
                      fileType: _vm.AudioFileType.Wav,
                    })
                  },
                },
              },
              [_c("icon", { attrs: { name: "download" } }), _vm._v("WAV")],
              1
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Download Trackout",
                    expression: "'Download Trackout'",
                  },
                ],
                staticClass: "btn btn--border btn--white",
                class: { isDisabled: !_vm.license.trackout },
                on: {
                  click: function ($event) {
                    return _vm.downloadFileType({
                      beatId: _vm.beat.id,
                      fileType: _vm.AudioFileType.Trackout,
                    })
                  },
                },
              },
              [_c("icon", { attrs: { name: "download" } }), _vm._v("STEMS")],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.parentState !== _vm.ParentStateType.Purchases
      ? _c(
          "td",
          {
            staticClass:
              "table-cell table-cell--center table-cell--hide-on-mobile",
          },
          [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Demo Download",
                    expression: "'Demo Download'",
                  },
                ],
                class: { isDisabled: !_vm.beat.demo_download_allowed },
                on: {
                  click: function ($event) {
                    return _vm.demoDownloadBeat(_vm.beat)
                  },
                },
              },
              [
                _c("icon", {
                  staticClass: "icon-download",
                  attrs: { name: "download" },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.parentState === _vm.ParentStateType.Purchases
      ? _c(
          "td",
          {
            staticClass:
              "table-cell table-cell--center table-cell--hide-on-mobile",
          },
          [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.openPdfContact({ beatId: _vm.beat.id })
                  },
                },
              },
              [_vm._v("Download")]
            ),
          ]
        )
      : _vm._e(),
    _c(
      "td",
      { staticClass: "table-cell table-cell--center table-cell--mobile-only" },
      [
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                return _vm.toggleLike(_vm.beat)
              },
            },
          },
          [
            _c("icon", {
              staticClass: "icon-like",
              attrs: { name: _vm.beatIsLiked ? "heart" : "heart-o" },
            }),
          ],
          1
        ),
      ]
    ),
    _c("td", { staticClass: "table-cell table-cell--center" }, [
      _c(
        "a",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: "Add To Cart",
              expression: "'Add To Cart'",
            },
          ],
          staticClass: "btn btn--sm btn--white btn--border",
          on: {
            click: function ($event) {
              return _vm.SET_ACTIVE_MODAL({
                modal: _vm.ModalType.LicenseOptionSelection,
                payload: _vm.beat,
              })
            },
          },
        },
        [_vm._v(_vm._s(_vm.beatAlreadyInCart ? "In Cart" : "+ ADD"))]
      ),
    ]),
    _c(
      "td",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeMenuDropdown,
            expression: "closeMenuDropdown",
          },
        ],
        staticClass: "table-cell table-cell__dropdown",
      },
      [
        _c("div", { staticClass: "table-cell--hide-on-mobile dropdown" }, [
          _c(
            "a",
            { on: { click: _vm.toggleMenuDropdown } },
            [_c("icon", { attrs: { name: "open-menu" } })],
            1
          ),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuDropDown,
                  expression: "menuDropDown",
                },
              ],
              staticClass: "dropdown__menu dropdown__menu--right",
            },
            [
              _c("li", { staticClass: "dropdown__item" }, [
                _c("a", { on: { click: _vm.openShareBeatModal } }, [
                  _vm._v("Share"),
                ]),
              ]),
              _c("hr", { staticClass: "dropdown__divider" }),
              _c("li", { staticClass: "dropdown__item" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.toggleLike(_vm.beat)
                      },
                    },
                  },
                  [_vm._v("Like Beat")]
                ),
              ]),
              _vm.parentState !== _vm.ParentStateType.Purchases
                ? _c("li", { staticClass: "dropdown__item" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.demoDownloadBeat(_vm.beat)
                          },
                        },
                      },
                      [_vm._v("Demo Download")]
                    ),
                  ])
                : _vm._e(),
              _c("li", { staticClass: "dropdown__item" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.SET_ACTIVE_MODAL({
                          modal: _vm.ModalType.LicenseOptionSelection,
                          payload: _vm.beat,
                        })
                      },
                    },
                  },
                  [_vm._v("Add To Cart")]
                ),
              ]),
              _vm.parentState === _vm.ParentStateType.Purchases
                ? [
                    _c("hr", { staticClass: "dropdown__divider" }),
                    _c("li", { staticClass: "dropdown__item" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.downloadFileType({
                                beatId: _vm.beat.id,
                                fileType: _vm.AudioFileType.Mp3,
                              })
                            },
                          },
                        },
                        [_vm._v("Download Mp3")]
                      ),
                    ]),
                    _vm.license.wav
                      ? _c("li", { staticClass: "dropdown__item" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.downloadFileType({
                                    beatId: _vm.beat.id,
                                    fileType: _vm.AudioFileType.Wav,
                                  })
                                },
                              },
                            },
                            [_vm._v("Download Wav")]
                          ),
                        ])
                      : _vm._e(),
                    _vm.license.trackout
                      ? _c("li", { staticClass: "dropdown__item" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.downloadFileType({
                                    beatId: _vm.beat.id,
                                    fileType: _vm.AudioFileType.Trackout,
                                  })
                                },
                              },
                            },
                            [_vm._v("Download Trackout")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c("hr", { staticClass: "dropdown__divider" }),
              _vm.parentState !== _vm.ParentStateType.Producer
                ? _c(
                    "li",
                    { staticClass: "dropdown__item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Producer",
                              params: { slug: _vm.beat.producer.slug },
                            },
                          },
                        },
                        [_vm._v("Go To Producer")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.parentState !== _vm.ParentStateType.ArtistType
                ? _c(
                    "li",
                    { staticClass: "dropdown__item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "ArtistType",
                              params: { slug: _vm.beat.artist_type.slug },
                            },
                          },
                        },
                        [_vm._v("Go To Artist Type")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.parentState !== _vm.ParentStateType.Genre
                ? _c(
                    "li",
                    { staticClass: "dropdown__item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Genre",
                              params: { slug: _vm.beat.genre.slug },
                            },
                          },
                        },
                        [_vm._v("Go To Genre")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
        _c(
          "a",
          {
            staticClass: "table-cell--mobile-only",
            on: {
              click: function ($event) {
                return _vm.SET_ACTIVE_MODAL({
                  modal: _vm.ModalType.BeatAction,
                  payload: _vm.beat,
                })
              },
            },
          },
          [_c("icon", { attrs: { name: "open-menu" } })],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }